import { RenderHtml } from '@wix/answers-ui-libby';
import classNames from 'classnames';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { VoteOptions } from '../../../../common/types';
import { AdditionalFeedbackField } from './AdditionalFeedbackField';
import { useVisibleFeedbackBi } from './bi';
import { BiLogger } from '../../../routes/bi';

export type VisibleFeedbackProps = {
	hasAnchor: boolean;
	articleId: string;
	biLogger: BiLogger;
	t: (key: string) => string;
};

// This feedback variation was requested by 'CSI' team, and is used only by them instead of the default feedback component.
export const VisibleFeedback: FunctionComponent<VisibleFeedbackProps> = ({
	hasAnchor,
	articleId,
	biLogger,
	t
}: VisibleFeedbackProps) => {
	const [selectedOption, setSelectedOption] = useState<VoteOptions>();
	const [showThankYouMessage, setShowThankYouMessage] = useState(false);

	const options = [VoteOptions.YES, VoteOptions.UNHELPFUL, VoteOptions.UNSATISFIED, VoteOptions.OTHER];
	const shouldShowAdditionalFeedbackText = selectedOption && selectedOption !== VoteOptions.YES;
	const isOtherOptionSelected = selectedOption === VoteOptions.OTHER;
	const sessionStorageKey = `visible-feedback-${articleId}`;

	const bi = useVisibleFeedbackBi(biLogger, articleId);

	const getVoteFromSessionStorage = useCallback(() => {
		const vote = localStorage.getItem(sessionStorageKey);
		return vote ? JSON.parse(vote) : undefined;
	}, [sessionStorageKey]);

	const setVoteInSessionStorage = (vote: VoteOptions) => localStorage.setItem(sessionStorageKey, JSON.stringify(vote));

	useEffect(() => {
		const vote = getVoteFromSessionStorage();
		if (vote) {
			setSelectedOption(vote);
			setShowThankYouMessage(true);
		}
	}, [articleId]);


	const onBadgeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			if (e.target instanceof HTMLElement) {
				e.target.click();
			}
		}
	};

	const onSendFeedback = (feedbackText: string) => {
		setShowThankYouMessage(true);
		setVoteInSessionStorage(selectedOption as VoteOptions);
		void bi.onSubmitFreeText(feedbackText, t(`article-page.visible-feedback.${selectedOption}`));
	};

	const onOptionClick = (option: VoteOptions) => {
		const isPositiveFeedback = option === VoteOptions.YES;
		if(option !== selectedOption) {
			void bi.onVote(t(`article-page.visible-feedback.${option}`), isPositiveFeedback);
		};

		setSelectedOption(option);
		if (isPositiveFeedback) {
			setShowThankYouMessage(true);
			setVoteInSessionStorage(option);
		} else {
			setShowThankYouMessage(false);
		}
	};

	const thankYouMessage = useMemo(() => {
		if (selectedOption === VoteOptions.YES) {
			return t('article-page.visible-feedback.thank-you-yes');
		} else {
			return t('article-page.visible-feedback.thank-you');
		}
	}, [articleId, selectedOption]);


	return (
		<div className={classNames('visible-feedback-wrapper', { 'has-anchor': hasAnchor })}>
			<h4 className='title'>
				{t('article-page.visible-feedback.title')}
			</h4>

			<div className='feedback-options'>
				{options.map((option) => (
					<div key={option} tabIndex={-1} onKeyDown={onBadgeKeyDown} onClick={() => setSelectedOption(option)} className='feedback-option-wrapper'>
						<div className={classNames('feedback-option', { selected: option === selectedOption }, `feedback-option-${option}`)}
							tabIndex={0} onClick={() => onOptionClick(option)}>
							<span className='feedback-option-text'>
								{t(`article-page.visible-feedback.${option}`)}
							</span>
						</div>
					</div>
				))}
			</div>

			{!showThankYouMessage && shouldShowAdditionalFeedbackText ? (
				<AdditionalFeedbackField t={t} onSendFeedback={onSendFeedback} isOtherOptionSelected={isOtherOptionSelected} />
			) : null}

			{showThankYouMessage ? (
				<RenderHtml className='thank-you-message' html={thankYouMessage} />
			) : null}
		</div>
	);
};
