import { classNames } from '@wix/answers-lib';
import React from 'react';
import { FunctionComponent, useMemo, useState } from 'react';

export type AdditionalFeedbackFieldProps = {
	isOtherOptionSelected: boolean;
	onSendFeedback: (feedbackText: string) => void;
	t: (key: string) => string;
};

const MAX_ADDITIONAL_TEXT_LENGTH = 2000;

export const AdditionalFeedbackField: FunctionComponent<AdditionalFeedbackFieldProps> = ({
	isOtherOptionSelected,
	onSendFeedback,
	t
}: AdditionalFeedbackFieldProps) => {
	const [feedbackText, setFeedbackText] = useState<string>('');

	const title = useMemo(() => isOtherOptionSelected ?
		t('article-page.visible-feedback.additional-feedback-field.title-other') : t('article-page.visible-feedback.additional-feedback-field.title')
		, [isOtherOptionSelected, t]);

	const isSendButtonDisabled = !feedbackText && isOtherOptionSelected;

	const onSendFeedbackClick = () => {
		if (isSendButtonDisabled) {
			return;
		} else {
			onSendFeedback(feedbackText);
		}
	};

	return (
		<div className='additional-feedback-wrapper'>
			<span className='title'>
				{title}
			</span>
			<div className='feedback-text-wrapper'>
				<textarea
					className='feedback-text'
					placeholder={t('article-page.visible-feedback.text-placeholder')}
					rows={5}
					maxLength={MAX_ADDITIONAL_TEXT_LENGTH}
					value={feedbackText}
					onChange={(e) => setFeedbackText(e.target.value)}
				/>
			</div>

			<button disabled={!feedbackText && isOtherOptionSelected} onClick={onSendFeedbackClick} className={classNames('send-button', { disabled: isSendButtonDisabled })}>
				{t('article-page.visible-feedback.additional-feedback-field.send')}
			</button>
		</div>
	);
};
