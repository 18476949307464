import { classNames } from '@wix/answers-lib';
import * as React from 'react';
import { AGENT_DISABLED_NOTIFICATION_HEIGHT } from '../../../utils/disabled-helpcenter-notification';

export const anchorNavigationKey = 'anchor-navigation';
const classNameWithScroll = `${anchorNavigationKey} with-scroll`;

export interface AnchorNavigationProps {
  currentAnchorId: string;
  anchors: any[];
  withVisibleFeedbackAsSibling: boolean;
  onChangeAnchor(anchorId: string): void;
  t(key: string): string;
}

export interface AnchorNavigationState { }

export class AnchorNavigation extends React.PureComponent<
  AnchorNavigationProps,
  AnchorNavigationState
> {
  state: AnchorNavigationState = {};
  AnchorsRef!: HTMLDivElement;

  componentDidMount() {
    const currentAnchor = document.getElementById(
      `anchor-${this.props.currentAnchorId}`,
    );
    if (
      this.AnchorsRef &&
      this.AnchorsRef.scrollHeight - this.AnchorsRef.offsetHeight > 0
    ) {
      this.AnchorsRef.addEventListener('scroll', this.onScroll);
      this.AnchorsRef.setAttribute('class', classNameWithScroll);
    }
    if (currentAnchor && this.AnchorsRef) {
      //250 is a random number so the scroll will look good
      this.AnchorsRef.scrollTop = currentAnchor.offsetTop - 350;
    }
  }

  componentWillUnmount() {
    if (this.AnchorsRef) {
      this.AnchorsRef.removeEventListener('scroll', this.onScroll);
    }
  }

  onScroll = () => {
    const isScrolled = this.AnchorsRef.scrollTop > 0;
     if (isScrolled) {
      const maybeScrolledToBottom = (this.AnchorsRef.scrollHeight - this.AnchorsRef.scrollTop === this.AnchorsRef.clientHeight) ?
          'scrolled-to-bottom' : '';
      this.AnchorsRef.setAttribute('class', classNameWithScroll + ` scrolled ${maybeScrolledToBottom}`);
    }
    else {
      this.AnchorsRef.setAttribute('class', classNameWithScroll);
    }
  };

  getAnchorLevel = (level: number, levels: number[]) => {
    //in order to prevent situation where there are low levels without high levels - stylewise
    switch (level) {
      case 2:
        return level;
      case 3:
        return levels.includes(2) ? level : 2;
      case 4:
        return levels.includes(3) && levels.includes(2)
          ? level
          : levels.includes(3) || levels.includes(2)
          ? 3
          : 2;
      default:
        return level;
    }
  };

  onClick = (e: any) => {
    e.preventDefault();
  };

  updateAnchorReference = (elem: HTMLDivElement) => {
    this.AnchorsRef = elem;
  };

  getAnchorList = () => {
    const { anchors } = this.props;
    const levels = anchors.map((anchor) => anchor.value.level);
    const anchorsComp = anchors.map((anchor, idx) => {
      const anchorInView =
        this.props.currentAnchorId === anchor.value.anchorId ? 'in-view' : '';
      const anchorLevel = this.getAnchorLevel(anchor.value.level, levels);

      return (
        <li
          className={`anchor indent-${anchorLevel} ${anchorInView}`}
          key={idx}
          id={`anchor-${anchor.value.anchorId}`}
          onClick={() => this.props.onChangeAnchor(anchor.value.anchorId)}
        >
          <div className="border-container" />
          <a onClick={this.onClick} href="">
            {anchor.value.text}
          </a>
        </li>
      );
    });
    return anchorsComp;
  };

  render() {
    return (
      <div ref={this.updateAnchorReference} className={classNames(anchorNavigationKey, { 'with-visible-feedback-as-sibling': this.props.withVisibleFeedbackAsSibling })}>
        <div className="anchor-navigation-title">
          {this.props.t('anchor-navigation-title')}
        </div>
        <ul className="anchor-list">{this.getAnchorList()}</ul>
      </div>
    );
  }
}
