import * as React from 'react';
import * as ReactDom from 'react-dom';
import { BreadcrumbItem } from '../viewer/page-components/breadcrumbs';
import { HeroSectionData, HeroSectionLayout } from "@wix/answers-api"
import Url from 'url-parse';
import {
  HelpcenterPageType,
  HelpcenterColor,
  BrandingColorType,
  HelpcenterBrandingSettings,
  Category,
  Article,
  CustomFontData,
  ExtendedCategory,
  TicketSortType,
  HelpcenterSectionType,
  HelpcenterStructure
} from '@wix/answers-api';
import { createMemoryHistory, MemoryHistory } from 'history';
import { Router } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n-for-tests';
import { TranslateFn } from '@wix/answers-lib';
import { PROFILE_PAGE_TYPE, TICKET_PAGE_TYPE } from './helpcenter-types';
import { Option } from '../viewer/components-for-sections';

export const REFERRAL_SIDEBAR_MENU = 'sidebar_menu';
export const MAX_LIMIT_CATEGORY_ARTICLES = 50;
export const SEARCH_ARTICLES_PAGE_SIZE = 350;
export const RLT_LOCALES = ['he', 'ar'];
const articlesScrollLocationKey = 'ans_articlesScrollLocation';
const categoriesScrollLocationKey = 'ans_categoriesScrollLocation';
const categoryScrollLocationKey = 'ans_categoriesScrollLocation';
const requestListFiltersKey = 'ans_requestListFilters';

export function deepSearch<T, K extends keyof T>(
  data: T[],
  childrenKey: K,
  predicate: (item: T) => boolean,
): T | undefined {
  const found = data.find(predicate);
  if (found) {
    return found;
  }
  for (let i = 0, len = data.length; i < len; i++) {
    if (Array.isArray(data[i][childrenKey])) {
      const foundInner = deepSearch(
        data[i][childrenKey] as any,
        childrenKey,
        predicate,
      );
      if (foundInner) {
        return foundInner;
      }
    }
  }
}

export function deepBranch<T, K extends keyof T>(
  data: T[],
  childrenKey: K,
  predicate: (item: T) => boolean,
): T[] {
  const found = data.find(predicate);
  if (found) {
    return [found];
  }
  for (let i = 0, len = data.length; i < len; i++) {
    if (Array.isArray(data[i][childrenKey])) {
      const foundInner = deepBranch(
        data[i][childrenKey] as any,
        childrenKey,
        predicate,
      );
      if (foundInner && foundInner.length) {
        return [data[i], ...foundInner];
      }
    }
  }
  return [];
}

export const getApiUrl = `/api/v1`;

export const is_server = () => {
  return !(typeof window !== 'undefined' && window.document);
};

export const renderAndMountComp = <P extends {}>(
  Comp: React.ComponentType<any>,
  props: P,
) => {
  const elem = document.createElement('div');
  document.body.appendChild(elem);
  ReactDom.render(React.createElement(Comp, props), elem);
  return elem;
};

export function withMemoryRouter<T>(
  Component: React.ComponentType<T>,
  history: MemoryHistory = createMemoryHistory(),
) {
  return (props: T) => (
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <Component {...props}/>
      </Router>
    </I18nextProvider>
  );
}

export const renderAndMountComponent = (component: any): Element => {
  const elem = document.createElement('div');
  document.body.appendChild(elem);
  ReactDom.render(component as any, elem);
  return elem;
};

export function getBreadCrumbs(
  pageType: HelpcenterPageType,
  params: any,
  categoryTree: any,
  model: any,
  t: TranslateFn,
  includeArticle: boolean = false,
): BreadcrumbItem[] {
  switch (pageType) {
    case HelpcenterPageType.SUB_CATEGORIES:
    case HelpcenterPageType.CATEGORY:
      const {
        categoryUri0,
        categoryUri1,
        categoryUri2,
        categoryUri3,
        categoryUri4,
      } = params;
      const categoryUri = [
        categoryUri0,
        categoryUri1,
        categoryUri2,
        categoryUri3,
        categoryUri4,
      ]
        .filter((i) => !!i)
        .join('/');

      const category = deepSearch<Category, any>(
        categoryTree,
        'children',
        (item) => item.uri === `/${categoryUri}`,
      );

      if (category) {
        const breadcrumbs = deepBranch<Category, any>(
          categoryTree,
          'children',
          (item) => item.id === category.id,
        );
        return [
          { name: t('breadcrumb.all-topics'), uri: '/categories' },
        ].concat(
          breadcrumbs.map((cat) => ({
            name: cat.name,
            uri: cat.uri,
            id: cat.id,
          })),
        );
      }
      return [];
    case HelpcenterPageType.CATEGORIES:
      return [{ name: t('breadcrumb.all-topics'), uri: '/categories' }];
    case HelpcenterPageType.ARTICLE:
    case HelpcenterPageType.ARTICLE_FEATURE_REQUEST:
    case HelpcenterPageType.ARTICLE_KNOWN_ISSUE:
      if (model.article) {
        const breadcrumbs = deepBranch<any, any>(
          categoryTree,
          'children',
          (item) => item.id === model.article.categoryId,
        );
        return [
          { name: t('breadcrumb.all-topics'), uri: '/categories' },
          ...breadcrumbs.map((cat) => ({
            name: cat.name,
            uri: cat.uri,
            id: cat.id,
          })),
          ...(includeArticle
            ? [
                {
                  name: (model.article as Article).title,
                  uri: (model.article as Article).uri,
                  id: (model.article as Article).id,
                },
              ]
            : []),
        ];
      }
      return [];
    case HelpcenterPageType.SEARCH_RESULTS:
      return [{ name: t('breadcrumb.search-results'), uri: '' }];
    case HelpcenterPageType.CONTACT:
      return [{ name: t('breadcrumb.contact-us'), uri: '' }];
    case PROFILE_PAGE_TYPE:
      return [{ name: t('breadcrumb.profile'), uri: '' }];
    case TICKET_PAGE_TYPE:
      return [
        { name: t('breadcrumb.profile'), uri: '/account' },
        { name: t('breadcrumb.ticket'), uri: '' },
      ];
    default:
      return [];
  }
}

export const isInternalLink = (
  url: string,
  host: string,
  tenantName?: string,
): false | { uri: string; locale: string } => {
  const urlObj = new Url(url);
  const formatMatch = urlObj.pathname.match('/(.{2,4})/article/(.*)');

  if (host && urlObj.hostname === host && formatMatch) {
    return { locale: formatMatch[1], uri: formatMatch[2] };
  }

  const answersHost = tenantName && `${tenantName}.wixanswers.com`;
  if (answersHost && urlObj.hostname === host && formatMatch) {
    return { locale: formatMatch[1], uri: formatMatch[2] };
  }

  // legacy support for Wix articles
  const wixFormatMatch = url.match('/(.{2,4})(?:/.+)?/kb/(.*)');
  if (url.includes(host) && wixFormatMatch) {
    return { locale: wixFormatMatch[1], uri: wixFormatMatch[2] };
  }

  return false;
};

export const getUrlWithKb = (url: string, locale: string) => {
  const localeIdx = url.indexOf(`/${locale}/`);
  return !url.match(/\/kb\//)
    ? `${url.slice(0, localeIdx)}/kb${url.slice(localeIdx)}`
    : url;
};

export const colorResolver = (
  branding: HelpcenterBrandingSettings,
  color?: HelpcenterColor,
) => {
  if (color) {
    switch (color.type) {
      case BrandingColorType.CUSTOM:
        return color.customColor || '';
      case BrandingColorType.MAIN:
        return branding.brandColor;
      case BrandingColorType.ACTION:
        return branding.actionColor;
      case BrandingColorType.BACKGROUND:
        return branding.pagesBackgroundColor;
      default:
        return '';
    }
  }
  return '';
};

export const saveScrollPosition = (
  pageStructureType: HelpcenterPageType,
  window: Window,
) => {
  try {
    switch (pageStructureType) {
      case HelpcenterPageType.ARTICLE:
        localStorage.setItem(
          articlesScrollLocationKey,
          JSON.stringify(window.pageYOffset),
        );
        break;
      case HelpcenterPageType.CATEGORIES:
        localStorage.setItem(
          categoriesScrollLocationKey,
          JSON.stringify(window.pageYOffset),
        );
        break;
      case HelpcenterPageType.CATEGORY:
        localStorage.setItem(
          categoryScrollLocationKey,
          JSON.stringify(window.pageYOffset),
        );
        break;
      default:
    }
  } catch (err) {}
};

export const getHeroForSearchResult = (helpcenterStructure: HelpcenterStructure) => {
  const { sections } = helpcenterStructure.pages.homePage;
  const heroSection: HeroSectionData = {...sections.find((section) => section.type === HelpcenterSectionType.HERO) as HeroSectionData};
  if (!!heroSection) {
    heroSection.config = {
    ...heroSection.config,
    showSearchBar: true,
    layout: HeroSectionLayout.COMPACT
    }
    heroSection.id = heroSection.id + 'search-results'
    return heroSection;
  } else {
    return null;
  }
}

export const restoreScrollPosition = (
  pageStructureType: HelpcenterPageType,
  window: Window,
) => {
  switch (pageStructureType) {
    case HelpcenterPageType.ARTICLE:
      const articlesScrollLocation = localStorage.getItem(
        articlesScrollLocationKey,
      );
      if (articlesScrollLocation) {
        window.scrollTo({ top: parseInt(articlesScrollLocation, 10) });
      }
      break;
    case HelpcenterPageType.CATEGORIES:
      const categoriesScrollLocation = localStorage.getItem(
        categoriesScrollLocationKey,
      );
      if (categoriesScrollLocation) {
        window.scrollTo({ top: parseInt(categoriesScrollLocation, 10) });
      }
      break;
    case HelpcenterPageType.CATEGORY:
      const categoryScrollLocation = localStorage.getItem(
        categoryScrollLocationKey,
      );
      if (categoryScrollLocation) {
        window.scrollTo({ top: parseInt(categoryScrollLocation, 10) });
      }
      break;
    default:
  }
};

export const getArticleTextContent = (article: Article) => {
  return article.content
    .replace(/<[^>]*>?/gm, '')
    .replace(/&quot;/gm, '"')
    .replace(/&apos;/gm, "'")
    .replace(/&#39;/gm, "'")
    .replace(/&lt;/gm, '<')
    .replace(/&gt;/gm, '>')
    .replace(/&amp;/gm, '&')
    .substring(0, 210);
};

export const getCustomFontData = (fontData: string): CustomFontData => {
  const FONT_WEIGHTS = [400, 500, 600];
  const isCssFontData = !!fontData && /@font-face[ ]?{/.test(fontData);

  if (isCssFontData) {
    const fontWeights =
      isCssFontData &&
      FONT_WEIGHTS.filter((fontWeight) =>
        new RegExp(`font-weight:[ ]?${fontWeight}`).test(fontData),
      );
    const fontFamilyDataArr = fontData.match(/(?<=font-family:[ '"]?).+;/);
    const fontFamilyData = fontFamilyDataArr && fontFamilyDataArr[0].split(';');
    const fontName =
      (isCssFontData &&
        fontFamilyData &&
        fontFamilyData[0] &&
        fontFamilyData[0].replace(/^\s+|['";]+/g, '')) ||
      '';

    return {
      isValidData: isCssFontData,
      hasAllFontWeights:
        fontWeights && fontWeights.length === FONT_WEIGHTS.length,
      fontName,
    };
  }
  return {
    isValidData: isCssFontData,
    hasAllFontWeights: false,
    fontName: '',
  };
};

export const getCategoryArticlesLimit = (category?: ExtendedCategory) => {
    const categoryArticlesCount = category?.publishedArticleCount;
    return categoryArticlesCount && categoryArticlesCount <= MAX_LIMIT_CATEGORY_ARTICLES ?
      categoryArticlesCount : MAX_LIMIT_CATEGORY_ARTICLES;
}

export const getDir = (locale: string) => RLT_LOCALES.includes(locale) ? 'rtl' : 'ltr';

export interface RequestListFilters {
  text?: string;
  status?: Option[];
  company?: Option[];
  reporter?: Option[];
  language?: Option[];
  page?: number;
  sortType?: TicketSortType;
}

export const saveRequestListFilters = (filters: RequestListFilters) => {
  localStorage.setItem(requestListFiltersKey, JSON.stringify(filters));
};

export const getRequestListFilters = () => {
  const requestListFilters = localStorage.getItem(requestListFiltersKey);
  return JSON.parse(requestListFilters || '{}') as RequestListFilters;
};
