import { csiSubmitWasItHelpfulFormSrc77Evid2011 } from '@wix/bi-logger-customer-care-data/v2';
import { publicVoteForArticle } from '@wix/bi-logger-wix-answers/v2';
import { BiLogger } from '../../../routes/bi';

export const useVisibleFeedbackBi = (biLogger: BiLogger, articleId: string) => {

	const onVote = (clickedText: string, positive: boolean) => {
		biLogger.report(publicVoteForArticle({
			article_id: articleId,
			clicked_text: clickedText,
			thumbs_up: positive,
		}));
	};

	const onSubmitFreeText = (FeedbackText: string, feedbackReason: string) => {
		biLogger.report(csiSubmitWasItHelpfulFormSrc77Evid2011({
			article_id: articleId,
			feedback_text: FeedbackText,
			feedback_reason: feedbackReason,
			thumbs_up: false,
		}));
	};

	return {
		onVote,
		onSubmitFreeText,
	};
};
