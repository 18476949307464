import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { classNames } from '@wix/answers-lib';

interface NavLinkJsxProps extends WithTranslation {
  uri: string;
  state?: string | { navigation: boolean; categoryId: string };
  className?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export const NavLinkJsx = withTranslation()<NavLinkJsxProps>(
  ({ i18n, uri, children, state, className, onClick }) => {
    const locale = i18n.language;
    const _uri = uri.trim();
    const pathname = `/${locale}` + (_uri.startsWith('/') ? _uri : `/${_uri}`);
	const pathnameWithoutQuery = pathname.split('?')[0];
	//TODO: CHECK THIS OUT
    return (
      <NavLink
        onClick={(e: any)=>{onClick && onClick(e)}}
        to={{ pathname, state }}
        className={classNames('nav-link', className)}
		isActive={()=>typeof window !== 'undefined' && window.location?.pathname === pathnameWithoutQuery}
      >
        {children}
      </NavLink>
    );
  },
);
