import { publicCustomerPortalViewerClientEngagement, publicSelectSearchResult } from '@wix/bi-logger-wix-answers/v2';
import { BiLogger } from '../../routes/bi';

export const useNavLinksBi = (biLogger: BiLogger, articleId: string) => {
	const sendPublicCustomerPortalViewerClientEngagement = (answers_brand_id: string) => {
		void biLogger.report(publicCustomerPortalViewerClientEngagement({
			data: articleId,
			engaged_component: 'followed_articles_list',
			engagement_name: 'click_on_view_an_article',
			answers_brand_id,
		}));
	};

	const sendSearchResultsClick = (searchTerm: string, index: number) => {
		void biLogger.report(publicSelectSearchResult({
			search_term: searchTerm,
			article_id: articleId,
			answer_index: index,
			search_result_selection: 'full_search_results_list',
		}));
	};

	return {
		sendPublicCustomerPortalViewerClientEngagement,
		sendSearchResultsClick,
	};
}
